<template>
  <div>
    <transition name="fade" v-if="loading">
      <loading-component></loading-component>
    </transition>

    <transition v-if="!loading" name="fade">
      <div id="wrapper">
          <sidebar :page="'Home'" :sidebar_data=sidebar_data></sidebar>

          <div class="backdrop" @click="close()"></div>

          <nav :class="'navbar bg-transparent p-0 pb-3'">
              <div class="container-fluid">
                  <div class="navbar-brand bars me-2 mt-2" @click="this.$router.push({name: 'Home'})">
                    <i class="ph-arrow-left"></i>
                  </div>

                  <img :src="require('../assets/img/logo-orange.png')" alt="" style="height: 75px;">

                  <notifications :user="user" :color_notifications_icons="'orange'"/>

                  <div class="divider mt-4 d-none"></div>
              </div>
          </nav>

          <floating-nav :page="'Cart'"></floating-nav>

          <div class="body mb-4" style="min-height: auto">
              <div class="container" style="padding: 0rem 2rem 0 2rem">          
                <div class="row">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <h2 class="text-orange">My Cart</h2>
                    </div>          
                  </div>

                  <div class="container-fluid">
                      <div class="row">
                          <div class="col-12">
                              <status v-model:status_data=status_data></status>
                          </div>
                      </div>
                  </div>                

                  <span class="mt-4" v-if="cart_stock.length > 0">
                    <div class="alert alert-success align-items-center fw-bold w-100 p-2 text-center" role="alert" v-if="cart_stock[0].prescription_cart_id && cart_stock[0].status">
                      <p class="mb-1 fs-6"> Your cart has products awaiting presciptions.</p>
                      <small class="fst-italic fw-lighter">After they are reviewed, you can have them delivered to you. </small>
                    </div>
                    
                    <div class="col-md-6 col-sm-12 col-lg-6"  v-for="({name, description, stock_type, price, quantity, image_url, requested_prescription, id}, index) in cart_stock"  :key="index">
                        <div class="card mb-3" style="cursor: pointer; box-shadow: 0 4px 6px #eee; border-color: #fafafa">
                            <div class="card-body p-3">
                                <div class="d-flex">
                                  <div class="alert bg-transparent text-orange-secondary align-items-center fw-bold w-100 p-2 text-end" role="alert" v-if="requested_prescription">
                                    <font-awesome-icon class="me-2" :icon="['fa', 'ban']"></font-awesome-icon> Awaiting Presciption
                                  </div>
                                </div>
                                
                                <div class="d-flex">
                                    <img v-if="image_url != '0' && image_url" :src="axios.defaults.baseURL + '/' + image_url" alt="" class="me-4" style="width: 90px; height: 90px; border-radius: 4px;">

                                    <img v-if="image_url == '0'  || !image_url" src="https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80" alt="" class="me-4" style="width: 134.1px; height: 90px; border-radius: 4px;">

                                    <div class="row">
                                        <h6 class='text-dark fw-bold mt-2 text-capitalize mb-1'> {{ name }} </h6>
                                        <small class="mb-2">{{ quantity }} items | {{ stock_type }}</small>

                                        <p>{{ description.substr(0, 50) }}...</p>
                                    </div>
                                </div>
                                <div class="d-flex">
                                  <button class="btn btn-sm bg-orange text-white rounded ps-2 pe-2 pt-0 pb-0" v-if=" !cart_stock[cart_stock.length-1].if_any_prescription_requests">
                                    <font-awesome-icon :icon="['fas', 'minus']" @click="removeFromCart(id)"></font-awesome-icon>
                                  </button>

                                  <p p class="mb-0 text-white bg-dark fw-bold ps-2 pe-2 rounded-3 ms-auto" style="padding: 2px;">Ksh. {{ price * quantity }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 mb-2">
                      {{ cart_stock[0].prescription_cart_id }}
                      <button class="btn bg-orange text-white w-100" type="button" @click="this.$router.push({name: 'ChoosePrescription', drugs})" v-if="if_prescription">
                        Next <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
                      </button>

                      <button class="btn bg-orange text-white w-100" type="button" @click="this.$router.push({name: 'Delivery'})" v-if=" !if_prescription">
                        Next Steps <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
                      </button>
                    </div>
                  </span>

                  <span class="mt-4" v-if="cart_stock.length == 0">
                    <h1 class="mx-auto text-center cancel mb-0 mt-4">
                      <font-awesome-icon class="" :icon="['fas', 'cart-plus']"></font-awesome-icon>
                    </h1>
                    <h6 class="text-center mt-2 text-secondary" style="letter-spacing: .5px">
                      You have nothing in your cart.
                    </h6> 
                  </span>

                  <div id="load-more" class="col-md-12 col-sm-12 p-4 pt-1">
                    <div class="card bg-transparent bg-gradient border-0">
                    <div class="card-body"></div>
                    </div>
                  </div>          
                </div>
              </div>
          </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  @import '../assets/scss/layout/_navbar.scss';
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .input-group-text {
    font-size: .65rem;
  }

  .cancel {
    font-size: 9rem;

    @include media('<tablet') {
      font-size: 4rem;
    }
  }

</style>

<script>
  import Sidebar from '../components/AutoSidebar'
  import FloatingNav from '../components/FloatingNav'
  import Status from '../components/Status'
  import Notifications from '../components/Notifications'

  export default {
    name: 'Cart',
    components: {
      Sidebar,
      FloatingNav,
      Status,
      Notifications
    },   
    data() {
      return {
        sidebar_data: [
          {title: 'Home', link: '', if_children: false, icon: 'home', modal: false},
          {title: 'My Account', link: 'profile', if_children: false, icon: 'user', modal: false},
        ],
        errors: {},
        screen: 0,
        stock_type: '',
        type: '',
        chosen_stock: null,
        quantity: null,
        search: null,
        stocks: [],
        status_data: { 
          success: false,
          success_message: 'You\'re in! Login is Successful!',
          error: false,
          error_message: 'There are some input errors.',
          loading: false
        },        
      }
    },
    watch: {
      user: function() {
        this.ifNotLoggedIn()
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      cart_stock () {
        return this.$store.state.cart_stock
      },
      if_prescription () {
        return this.$store.state.if_prescription
      }, 
    },    
    mounted() {
      this.$store.dispatch("getUser")
      this.$store.dispatch("getStockInCart")
    },
    methods: {
      ifNotLoggedIn() {
        if( !this.user.name) {
          this.$router.push({name: 'Login'});
        } 
      },
      removeFromCart(id) {
        this.status_data.loading = true

        this.axios.delete('api/cart/' + id).then(() => {
          this.status_data.loading = false
          this.$store.dispatch("getStockInCart")
        }).catch(() => {
          this.status_data.loading = false
        })
      },
    }    
  }
</script>
